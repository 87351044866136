import React, { useState, useEffect, useRef, useCallback } from "react";
import { formatStringToNumber, uuid } from "src/utils/common";
import { Tooltip } from "reactstrap";

const EditableInput = ({ fieldName, initialValue = "", handleSave = () => {}, showLabel, disabled, level = "", type, errors }) => {
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(initialValue);
  const [isHovered, setIsHovered] = useState(false);
  const divRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [uuidState, setUuid] = useState("");

  useEffect(() => {
    // Generate a UUID when the component mounts
    setUuid(`input-${uuid()?.toString()}`);
  }, []); // Empty dependency array ensures this runs only once

  const handleSaveData = () => {
    setIsEditing(false);
    const newVal = String(tempValue).trim();

    if (fieldName === "name") {
      setValue(newVal);
      setTempValue(newVal);
      handleSave(fieldName, newVal);
      const textarea = document.querySelector(`#${uuidState}`);
      textarea?.blur();
      return;
    }

    if (type === "textarea") {
      setValue(newVal);
      setTempValue(newVal);
      handleSave(fieldName, newVal);
      const textarea = document.querySelector(`#${uuidState}`);
      textarea?.blur();
      textarea.scrollTop = 0;
      return;
    }

    if (!newVal) {
      setValue(initialValue);
      setTempValue(initialValue);
      const textarea = document.querySelector(`#${uuidState}`);
      textarea?.blur();
      textarea.scrollTop = 0;
      return;
    }
    if (type === "number") {
      const val = formatStringToNumber(newVal);

      setValue(val);
      setTempValue(val);
      handleSave(fieldName, val);
      const textarea = document.querySelector(`#${uuidState}`);
      textarea?.blur();
      return;
    }
    setValue(newVal);
    setTempValue(newVal);
    handleSave(fieldName, newVal);
    const textarea = document.querySelector(`#${uuidState}`);
    textarea?.blur();
    textarea.scrollTop = 0;
  };

  const handleBlur = () => {
    if (showLabel) {
      setIsEditing(false);
    }

    handleSaveData();
  };

  const handleClick = () => {
    if (disabled) return;
    setIsHovered(false);
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && type !== "textarea") {
      handleSaveData();
      return;
    }
    if (e.metaKey && e.key === "Enter") {
      handleSaveData();
      return;
    }
  };

  useEffect(() => {
    if ((initialValue === null || initialValue === "") && !showLabel) {
      setValue("");
      setTempValue("");
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setValue(initialValue);
      setTempValue(initialValue);
    }
  }, [initialValue]);

  const checkOverflow = useCallback(() => {
    if (type === "textarea" && uuidState) {
      const textarea = document.querySelector(`#${uuidState}`);
      const totalHeight = textarea.scrollHeight;
      const visibleHeight = textarea.clientHeight;

      setIsOverflowing(totalHeight > visibleHeight);
      return;
    }
    const element = divRef.current;
    if (element) {
      const BUFFER_WIDTH = 2;
      setIsOverflowing(element.scrollWidth > element.clientWidth + BUFFER_WIDTH);
    }
  }, [uuidState, value]);

  useEffect(() => {
    checkOverflow(); // Initial check
  }, [value, checkOverflow, uuidState]);

  if (!uuidState) {
    // Render nothing or a loading state if UUID hasn't been generated yet
    return null;
  }

  return (
    <div
      className={`input-wrapper input-group input-group-sm input-wrapper position-relative`}
      onMouseEnter={() => {
        if (isOverflowing && value && tempValue) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      {type === "textarea" ? (
        <textarea
          id={uuidState}
          ref={divRef}
          type={type || "text"}
          className={`input form-control ${
            isEditing ? "px-1 bg-white" : `border-transparent px-1 bg-transparent disabled-editable-input`
          } text-input`}
          value={isEditing ? tempValue : value}
          onClick={handleClick}
          onFocus={handleClick}
          onChange={(e) => setTempValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          name={level}
          disabled={disabled}
          rows={3}
        />
      ) : (
        <input
          id={uuidState}
          ref={divRef}
          // type={type || "text"}
          className={`input form-control ${
            isEditing ? "px-1 bg-white" : `border-transparent px-1 bg-transparent disabled-editable-input`
          } ${errors ? "border-danger" : ""} text-input`}
          value={isEditing ? tempValue : value}
          onClick={handleClick}
          onFocus={handleClick}
          onKeyPress={(event) => {
            if (type === "number" && !/^-?\d*\.?\d*$/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={(e) => setTempValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          readOnly={!isEditing}
          name={level}
          disabled={disabled}
        />
      )}

      {errors && <div className="text-danger fs-11">{errors}</div>}

      <Tooltip isOpen={isHovered} target={uuidState} container="body" className="z-i-1050 position-fixed w-100 max-width-by-px-300">
        <div className="text-prewrap">{value}</div>
      </Tooltip>
    </div>
  );
};

export default EditableInput;
