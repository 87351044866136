import React, { useState } from "react";
import { 
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FormInput from "../Form/FormInput";
import FormSelect from "../Form/FormSelect";
import { useForm } from "react-hook-form";
import { useMutation } from "src/Hooks/useReactQuery";
import { useSetRecoilState } from 'recoil';
import { alertState } from 'src/recoil/atoms/global';
import { createRocketModel, getRocketModel } from "src/services/rocket-model";
import APP_ROUTES from "../../../routes/routes";
import FormTextarea from "../Form/FormTextarea";

const FORM_NAME = {
  NAME: "name",
  STAGE: "number_of_stage",
  REMARKS: "remarks",
};

const ModelAddRocketModel = ({ onSuccess }) => {
  const { t } = useTranslation("rocket-model", "common");
  const [toggle, setToggle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const showAlert = useSetRecoilState(alertState);
  const navigate = useNavigate();

  const createRocketModelMutate = useMutation({
    mutationFn: ({ data }) => createRocketModel({ data }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {
    number_of_stage: 2, // Set default value for number of stages
  }});

  const handleSubmitForm = async (data) => {
    const inputData = {
      name: data.name.trim(),
      number_of_stage: data.number_of_stage,
      remarks: data.remarks,
    };
    setIsSubmit(true);
    
    try {
      const existingRocketModels = await getRocketModel({ name: inputData.name });
      if (existingRocketModels && existingRocketModels.length > 0) {
        showAlert({
          status: true,   
          message: t('new.name_conflict'), 
          type: 'error',
        });
        setIsSubmit(false);
        return;
      }
      createRocketModelMutate.mutate(
        { data: inputData },
        {
          onSuccess: (response) => {
            setToggle(false);
            onSuccess();
            showAlert({
              status: true,   
              message: t('new.create_success'), 
              type: 'success',
            });
            const createdRocketModelId = response?.id;
            if (createdRocketModelId) {
              navigate(`${APP_ROUTES.ROCKET_MODEL}/${createdRocketModelId}`);
            }
          },
          onSettled: () => {
            setIsSubmit(false);
          },
        }
      );    
    } catch (error) {
      console.error("Error checking rocket model:", error);
      showAlert({
        status: true,
        message: t('new.error_checking'),
        type: 'error',
      });
      setIsSubmit(false);
    }
  };
  return (
    <>
      <button
        onClick={() => {
          reset();
          setToggle(true);
        }}
        className="btn btn-primary text-capitalize"
      >
        {t("list.redirect_new")}
      </button>
      <Modal centered isOpen={toggle} toggle={() => setToggle(false)} size="lg" backdrop="static">
        <ModalHeader 
          toggle={() => setToggle(false)}
        >
          {t("new.title")}
        </ModalHeader>
        <ModalBody className="p-b-0">
          <Form className="p-x-15">
            <div className="row">
              <div className="col-md-12">
                <FormInput
                  register={register}
                  label={t("new.name")}
                  name={FORM_NAME.NAME}
                  placeholder={t("new.name_placeholder")}
                  errors={errors?.[FORM_NAME.NAME]?.message}
                  requiredMessage={t("new.name_required")}
                  required
                  maxLength={255}
                  maxLengthMessage={t("new.name_maxlength")}
                />
                <FormSelect
                  label={t("new.stage")}
                  name={FORM_NAME.STAGE}
                  placeholder="2"
                  control={control}
                  errors={errors?.[FORM_NAME.STAGE]?.message}
                  required
                  options={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 3, label: "3" },
                  ]}
                  requiredMessage={t("new.stage_required")}
                />
                <FormTextarea
                  register={register}
                  label={t("new.remarks")}
                  name={FORM_NAME.REMARKS}
                  placeholder={t("new.remarks_placeholder")}
                  errors={errors?.[FORM_NAME.REMARKS]?.message}
                />
              </div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter className="justify-content-center p-t-10 p-b-20">
          <button
            className="btn btn-sm btn-secondary m-x-10 width-by-px-120"
            onClick={() => {
              setToggle(false);
              document.body.click();
            }}
          >
            {t("new.cancel")}
          </button>
          <button
            className="btn btn-sm btn-primary m-x-10 width-by-px-120"
            disabled={isSubmit}
            onClick={handleSubmit((data) => handleSubmitForm(data))}
          >
            {t("new.register")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModelAddRocketModel;
