import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Collapse } from "reactstrap";
import { HISTORIES_ACTION_NAME, MAPPING_HISTORIES_ACTION_TO_TEXT } from "src/constant/common";
import LoadingWrapper from "src/components/Common/Table/Loading";
import { MAPPING_CONTENT_CHANGE_TO_TEXT } from "src/utils/common";

const HistoryLogs = ({ logs, loadingHistories }) => {
  const LIMIT_LOGS = 5;
  const { t } = useTranslation(["rocket-model"]);
  const [data, setData] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [show, setShow] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [init, setInit] = useState(true);

  const handleShowAll = (e) => {
    e.preventDefault();
    setShowAll(false);
    setShow(true);
    setTimeout(() => {
      const logsContainer = document.getElementById("logs");
      logsContainer?.scrollIntoView({ behavior: "instant", block: "end" });
    });
  };

  useEffect(() => {
    if (logs) {
      setData(logs);
      if (init && logs?.pagination?.total > LIMIT_LOGS) {
        setShowAll(!!(logs?.pagination?.total > LIMIT_LOGS));
        setInit(false);
      }
    }
  }, [logs]);

  const renderContent = (i) => {
    const showDetail = [
      HISTORIES_ACTION_NAME.UPDATE_ENGINE_COMPONENT,
      HISTORIES_ACTION_NAME.ADD_NEW_CALCULATION,
      HISTORIES_ACTION_NAME.UPDATE_ROCKET_MODEL,
      HISTORIES_ACTION_NAME.UPDATE_COMPONENT_DETAIL,
      HISTORIES_ACTION_NAME.RUN_SIMULATION,
      HISTORIES_ACTION_NAME.CALCULATE_THRUST,
      HISTORIES_ACTION_NAME.SAVE_UNPROCESSED_COMPONENT_DETAILS,
    ].includes(i.action_name);
    const id = i?.action_name === HISTORIES_ACTION_NAME.CALCULATE_THRUST ? i?.new_value?.id : null;
    return (
      <div className="row px-3 pb-2">
        <div className="col-12 border-top d-flex align-items-center pt-3 mb-2 gap-2">
          <div className="d-flex align-items-center w-auto">
            <img className="rounded-circle" src={i?.created_by?.image_url} alt="" width="48" height="48" />
          </div>
          <div className="w-auto">
            <p className="mb-0">
              <span className="fw-bold">{i?.created_by?.name}</span>{" "}
              {t("logs.content", { type: MAPPING_HISTORIES_ACTION_TO_TEXT(t)[i.action_name] ?? t("logs.updated") })} at{" "}
              <span className="text-secondary">{format(i?.updated_at, "MMM dd, yyyy HH:mm")}</span>
            </p>
          </div>
        </div>
        {showDetail && (
          <div className="col-12">
            <ul className="mb-0 m-l-60">
              <li
                className="list-item-white"
                dangerouslySetInnerHTML={{
                  __html: MAPPING_CONTENT_CHANGE_TO_TEXT(t, i?.comments, id, i?.new_value)[i?.action_name](),
                }}
              />
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <LoadingWrapper loading={loadingHistories}>
      <div className="row" id="logs">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <button className="btn btn-blank text-dark border-0 p-0 header-title" onClick={() => setShowContent(!showContent)}>
                {t("logs.title")} <i className={showContent ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></i>
              </button>

              <Collapse isOpen={showContent}>
                {showAll && (
                  <div className="text-center mb-1">
                    <span className="text-primary" role="button" onClick={(e) => handleShowAll(e)}>
                      {t("logs.load_more_count", { count: data?.pagination?.total - LIMIT_LOGS })}
                    </span>
                  </div>
                )}

                {show &&
                  data?.items
                    ?.slice(0, data?.items?.length - LIMIT_LOGS)
                    ?.map((i, index) => <Fragment key={index}>{renderContent(i)}</Fragment>)}
                {data?.items
                  ?.slice(data?.items?.length > LIMIT_LOGS ? data?.items?.length - LIMIT_LOGS : 0, data?.items?.length)
                  ?.map((i, index) => (
                    <Fragment key={index}>{renderContent(i)}</Fragment>
                  ))}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default HistoryLogs;
