import React from "react";
import { useTranslation } from "react-i18next";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { roundToTwoDecimals } from "src/utils/common";

// Registering both ChartJS and annotationPlugin components
Chart.register(...registerables, annotationPlugin);

const MomentChart = ({ data = [], labels = [] }) => {
  const { t } = useTranslation(["rocket-model", "common", "test-analysis"]);

  const uniqueLabels = labels.map((label, index) => ({ x: label, y: data[index] }));

  const stepData = {
    labels: Array.from(new Set(labels)),
    datasets: [
      {
        label: ` ${t("detail.inertia")}`,
        data: uniqueLabels,
        fill: false,
        borderColor: "magenta",
        borderWidth: 2,
        stepped: "before",
      },
    ],
  };

  const options = {
    scales: {
      x: {
        title: {
          display: true,
          text: t("detail.l_axis_none_unit"),
        },
        ticks: {
          callback: (_, index) => {
            const uniqArray = [...new Set(labels)];
            return roundToTwoDecimals(uniqArray[index]);
          },
        },
      },
      y: {
        title: {
          display: true,
          text: t("detail.inertia"),
        },
      },
    },
    plugins: {
      legend: {
        display: false, // This will hide the legend
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset;
            const value = context.raw !== undefined ? context.raw : context.parsed.y;
            return dataset.label + ": " + value?.y?.toString();
          },
          title: (context) => roundToTwoDecimals(context?.[0]?.label),
        },
      },
    },
  };

  return <Line data={stepData} options={options} />;
};

export default MomentChart;
